import { Image } from "@chakra-ui/react";
import { SkipNext } from "@material-ui/icons";
import InputIcon from "@material-ui/icons/Input";

import { StreamSinkStatus } from "src/api/streamSinks";
import amazonS3Icon from "src/img/sinks/amazonS3.svg";
import azureBlobStorageIcon from "src/img/sinks/azureBlobStorage.svg";
import googleCloudStorageIcon from "src/img/sinks/googleCloudStorage.svg";

interface SinkConfigMetadata {
  key: string;
  label: string;
  highlight?: boolean;
  placeholder?: string;
  description?: string;
  sensitive?: boolean;
  optional?: boolean;
}

interface SinkTypeData {
  name: string;
  values: Array<SinkConfigMetadata>;
  icon: React.ReactNode;
  description: string;
  enableTransformation: boolean;
}

// FIXME STREAM_APP_PORTAL: Add strong typing to ensure the keys are valid for each sink type
export const SinkTypesMetadata: Record<string, SinkTypeData> = {
  amazonS3: {
    name: "Amazon S3",
    icon: <Image src={amazonS3Icon} alt="Amazon S3" width="24px" height="24px" />,
    description: "Store events in an Amazon S3 bucket.",
    enableTransformation: false,
    values: [
      {
        key: "bucket",
        label: "Bucket",
        highlight: true,
        placeholder: "my-bucket",
      },
      {
        key: "region",
        label: "Region",
        highlight: true,
        placeholder: "us-east-1",
      },
      {
        key: "accessKeyId",
        label: "Access Key ID",
      },
      {
        key: "secretAccessKey",
        label: "Secret Access Key",
        sensitive: true,
        placeholder: "********",
      },
    ],
  },
  http: {
    name: "FIFO Endpoint",
    icon: <SkipNext />,
    description: "Receive events over HTTP in FIFO order.",
    enableTransformation: true,
    values: [
      {
        key: "url",
        label: "URL",
        highlight: true,
        description: "The URL to send the events to",
        placeholder: "https://example.com/events",
      },
    ],
  },
  snowflake: {
    name: "Snowflake",
    icon: <InputIcon />, // placeholder
    description: "Store events in a Snowflake database.",
    enableTransformation: false,
    values: [
      {
        key: "accountIdentifier",
        label: "Account Identifier",
        highlight: true,
        placeholder: "my-org-id-my-account-id",
        description:
          "Snowflake account identifier, which includes both the organization and account IDs separated by a hyphen",
      },
      {
        key: "userId",
        label: "User",
        highlight: true,
        placeholder: "my-user",
      },
      {
        key: "dbParams.dbName",
        label: "Database Name",
        placeholder: "my-database",
      },
      {
        key: "dbParams.schemaName",
        label: "Database Schema",
        placeholder: "my-schema",
      },
      {
        key: "dbParams.tableName",
        label: "Table Name",
        placeholder: "my-table",
      },
      {
        key: "privateKey",
        label: "Private Key",
        sensitive: true,
        placeholder: "********",
        description:
          "PEM-encoded private key used for signing token-based requests to the Snowflake API.",
      },
    ],
  },
  googleCloudStorage: {
    name: "Google Cloud Storage",
    icon: (
      <Image
        src={googleCloudStorageIcon}
        alt="Google Cloud Storage"
        width="24px"
        height="24px"
      />
    ),
    description: "Store events in a Google Cloud Storage bucket.",
    enableTransformation: false,
    values: [
      {
        key: "bucket",
        label: "Bucket",
        highlight: true,
        placeholder: "my-bucket",
      },
      {
        key: "credentials",
        label: "Credentials",
        sensitive: true,
        placeholder: "********",
        description: "Google Cloud Credentials JSON Object as a string.",
      },
    ],
  },
  azureBlobStorage: {
    name: "Azure Blob Storage",
    icon: (
      <Image
        src={azureBlobStorageIcon}
        alt="Azure Blob Storage"
        width="24px"
        height="24px"
      />
    ),
    description: "Store events in an Azure Blob Storage bucket.",
    enableTransformation: false,
    values: [
      {
        key: "container",
        label: "Container",
        highlight: true,
        placeholder: "my-container",
      },
      {
        key: "account",
        label: "Account",
        highlight: true,
        placeholder: "my-account",
      },
      {
        key: "accessKey",
        label: "Access Key",
        sensitive: true,
        placeholder: "********",
      },
    ],
  },
  otelV1HttpTrace: {
    name: "Otel V1 HTTP Trace",
    icon: <InputIcon />, // placeholder
    description: "Stream events to an OpenTelemetry Collector over HTTP.",
    enableTransformation: false,
    values: [
      {
        key: "url",
        label: "URL",
        highlight: true,
        placeholder: "https://example.com/events",
      },
    ],
  },
  bigQuery: {
    name: "BigQuery",
    icon: <InputIcon />, // placeholder
    description: "Store events in a BigQuery database.",
    enableTransformation: false,
    values: [
      {
        key: "projectId",
        label: "Project ID",
        highlight: true,
        placeholder: "my-project",
      },
      {
        key: "datasetId",
        label: "Dataset ID",
        highlight: true,
        placeholder: "my-dataset",
      },
      {
        key: "tableId",
        label: "Table ID",
        highlight: true,
        placeholder: "my-table",
      },
      {
        key: "credentials",
        label: "Credentials",
        sensitive: true,
        placeholder: "********",
        description: "Google Cloud Credentials JSON Object as a string.",
      },
    ],
  },
  redshift: {
    name: "Redshift",
    icon: <InputIcon />, // placeholder
    description: "Store events in a Redshift database.",
    enableTransformation: false,
    values: [
      {
        key: "accessKeyId",
        label: "Access Key ID",
        highlight: true,
        placeholder: "my-access-key",
      },
      {
        key: "secretAccessKey",
        label: "Secret Access Key",
        sensitive: true,
        placeholder: "********",
      },
      {
        key: "region",
        label: "Region",
        highlight: true,
        placeholder: "us-east-1",
      },
      {
        key: "dbUser",
        label: "Database User",
        placeholder: "my-user",
      },
      {
        key: "clusterIdentifier",
        label: "Cluster Identifier",
        placeholder: "my-cluster",
      },
      {
        key: "dbParams.dbName",
        label: "Database Name",
        placeholder: "my-database",
      },
      {
        key: "dbParams.schemaName",
        label: "Database Schema",
        placeholder: "my-schema",
      },
      {
        key: "dbParams.tableName",
        label: "Table Name",
        placeholder: "my-table",
      },
    ],
  },
};

export const StreamSinkStatusNames: Record<
  StreamSinkStatus,
  { name: string; color: string }
> = {
  [StreamSinkStatus.ENABLED]: {
    name: "Enabled",
    color: "green",
  },
  [StreamSinkStatus.RETRYING]: {
    name: "Retrying",
    color: "yellow",
  },
  [StreamSinkStatus.PAUSED]: {
    name: "Paused",
    color: "orange",
  },
  [StreamSinkStatus.DISABLED]: {
    name: "Disabled",
    color: "gray",
  },
};
