import {
  Box,
  Flex,
  HStack,
  Image,
  Stack,
  Text,
  CircularProgress,
  Heading,
  Link as StyledLink,
  Skeleton,
  SkeletonText,
  Tag,
  TagLabel,
  Thead,
  Tr,
  Th,
  Tbody,
  Tooltip,
} from "@chakra-ui/react";
import { Add, Info } from "@material-ui/icons";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import { useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import {
  EndpointApi,
  EndpointOut,
  EndpointStats,
  TransformationTemplateApi,
} from "svix/dist/openapi";

import Button from "@svix/common/widgets/Button";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";
import {
  PageToolbar,
  BreadcrumbItem,
  Breadcrumbs,
} from "@svix/common/widgets/PageToolbar";
import Table from "@svix/common/widgets/Table";
import TableCell from "@svix/common/widgets/TableCell";

import { SinkOut, SinksApi } from "src/api/sinks";
import { StreamSinkOut } from "src/api/streamSinks";
import EndpointsTour from "src/components/Tours/EndpointsTour";
import { useAppPagination, useAppQuery } from "src/hooks/api";
import { useOrgSettings } from "src/hooks/common";
import { useAppSelector } from "src/hooks/store";
import webhookIcon from "src/img/webhook.svg";
import { isEE } from "src/utils";
import ReadOnlyTooltip from "src/widgets/ReadOnlyTooltip";
import { getServerUrl, getSvix } from "../../api";
import { routeResolver } from "../../App";
import { templateIdMetadataField } from "../EndpointCreate/TemplateForm";
import StreamSinkRow from "../Stream/Sinks/StreamSinkRow";

function StatsChip(props: { stat?: EndpointStats; isLoading?: boolean }) {
  const { stat, isLoading } = props;
  if (isLoading) {
    return <CircularProgress isIndeterminate size="20px" mr={4} />;
  }

  if (!stat) {
    return null;
  }

  const total = stat.fail + stat.pending + stat.success + stat.sending;
  const errorRate = total > 0 ? (stat.fail + stat.pending) / total : 0;

  return (
    <Tag borderRadius="full" size="sm" colorScheme={errorRate > 0 ? "red" : "green"}>
      <TagLabel>{(errorRate * 100).toFixed(1)}%</TagLabel>
    </Tag>
  );
}

function EndpointRow(props: { endpoint: EndpointOut }) {
  const { endpoint } = props;
  const user = useAppSelector((state) => state.auth.user)!;
  const to = routeResolver.getRoute("endpoints._id", { endpId: endpoint.id });

  const templateId = endpoint.metadata[templateIdMetadataField];

  const { data: stats, isLoading: statsLoading } = useAppQuery(
    ["endpoints", endpoint.id, "stats"],
    async () => {
      const dh = getSvix();
      const config = dh._configuration;
      const endpApi = new EndpointApi(config);
      return endpApi.v1EndpointGetStats({
        appId: user.app.id,
        endpointId: endpoint.id,
      });
    },
    {
      failSilently: true,
      staleTime: 1000 * 60,
    }
  );

  const { data: template, isLoading: templateLoading } = useAppQuery(
    ["transformationTemplates", templateId],
    async () => {
      const dh = getSvix();
      const ttApi = new TransformationTemplateApi(dh._configuration);
      return ttApi.v1TransformationTemplateGet({ transformationTemplateId: templateId });
    },
    {
      enabled: !!templateId,
    }
  );

  return (
    <Tr opacity={endpoint.disabled ? "60%" : "100%"}>
      <TableCell to={to} scope="row" maxW={0} py={1}>
        <HStack spacing={4}>
          <Skeleton isLoaded={!templateLoading}>
            {template && (
              <Tooltip
                label={`Created using the ${template.name} transformation template`}
              >
                <Image
                  src={template.logo}
                  alt={`${template.name} icon`}
                  width="20px"
                  height="20px"
                />
              </Tooltip>
            )}
            {!template && (
              <Image src={webhookIcon} alt="Webhook icon" width="20px" height="20px" />
            )}
          </Skeleton>
          <Stack spacing={0.5} minH="36px" justifyContent="center">
            {isDynamicEndpointUrl(endpoint.url) ? (
              <HStack spacing={1}>
                <Text>Dynamic endpoint</Text>
                <Tooltip label="The URL of this endpoint is set dynamically on every message.">
                  <Info style={{ fontSize: "1rem", opacity: 0.4 }} />
                </Tooltip>
              </HStack>
            ) : (
              <Text lineHeight="normal">{endpoint.url}</Text>
            )}
            <Text variant="caption" noOfLines={1} isTruncated lineHeight="normal">
              {endpoint.description}
            </Text>
          </Stack>
        </HStack>
      </TableCell>
      <TableCell to={to} isNumeric>
        {endpoint.disabled && (
          <Tag
            size="sm"
            colorScheme="red"
            w="auto"
            mr={{ base: 0, lg: 2 }}
            mb={{ base: 1, lg: 0 }}
          >
            Disabled
          </Tag>
        )}
        <StatsChip stat={stats} isLoading={statsLoading} />
      </TableCell>
    </Tr>
  );
}

type WebhookEndpointListItem = { type: "endpoint"; data: EndpointOut };
type PollingEndpointListItem = { type: "sink"; data: SinkOut };
type StreamEndpointListItem = { type: "streamSink"; data: StreamSinkOut };

type EndpointListItem =
  | WebhookEndpointListItem
  | PollingEndpointListItem
  | StreamEndpointListItem;

type ListResponseEndpointsScreen = {
  data: EndpointListItem[];
  iterator: string | null;
  done: boolean;
};

export default function EndpointsScreen() {
  const queryClient = useQueryClient();
  const user = useAppSelector((state) => state.auth.user)!;

  const { data: orgSettings } = useOrgSettings();

  const [endpoints, endpointsCtx] = useAppPagination<ListResponseEndpointsScreen>(
    "endpoints-and-sinks",
    async (iterator) => {
      const items: EndpointListItem[] = [];
      const api = getSvix();
      const sinksApi = new SinksApi(api);

      const [pollingEndpointsList, streamSinksList, webhookEndpointsList] =
        await Promise.all([
          sinksApi.listSinks(user.app.id),
          isEE
            ? Promise.resolve({ data: [], iterator: null, done: true })
            : sinksApi.listStreamSinks(user.app.id).catch(() => {
                // Fail silently because this returns a 400 if the user has no stream sinks
                return { data: [], iterator: null, done: true };
              }),
          api.endpoint.list(user.app.id, { iterator }),
        ]);

      // First page - add sinks
      if (!iterator || iterator === "") {
        items.push(
          ...pollingEndpointsList.data
            .filter((s) => s.type === "eventStream")
            .map((s) => ({ type: "sink", data: s } as EndpointListItem))
        );
        queryClient.setQueryData(["endpoints", "sinks"], pollingEndpointsList);

        items.push(
          ...streamSinksList.data.map(
            (s) => ({ type: "streamSink", data: s } as EndpointListItem)
          )
        );
        queryClient.setQueryData(["endpoints", "streamSinks"], streamSinksList);
      }

      items.push(
        ...webhookEndpointsList.data.map(
          (e) => ({ type: "endpoint", data: e } as EndpointListItem)
        )
      );
      queryClient.setQueryData(["endpoints"], webhookEndpointsList);

      return {
        data: items,
        iterator: webhookEndpointsList.iterator,
        done: webhookEndpointsList.done,
      };
    }
  );

  const { isReadOnly, hideEventTypes } = useAppSelector((state) => state.embedConfig);

  const webhookEndpoints = endpoints?.data
    .filter((item) => item.type === "endpoint")
    .map((item) => item.data);

  const streamEndpoints = orgSettings?.enableMessageStream
    ? endpoints?.data
        .filter((item) => item.type === "streamSink")
        .map((item) => item.data)
    : [];

  const pollingEndpoints = endpoints?.data
    .filter((item) => item.type === "sink")
    .map((item) => item.data);

  return (
    <>
      <MetaTitle path={["Endpoints", user.app.name]} />
      <EndpointsTour />
      <Box id="endpoints-list">
        <PageToolbar>
          <Breadcrumbs>
            <BreadcrumbItem>Endpoints</BreadcrumbItem>
          </Breadcrumbs>
          <Flex flexGrow={1} />
          <ReadOnlyTooltip readOnly={isReadOnly}>
            <Button
              as={Link}
              size="sm"
              isDisabled={isReadOnly}
              to={isReadOnly ? "" : routeResolver.getRoute("endpoints.new")}
              variant="outlineSecondary"
              leftIcon={<Add />}
              id="add-endpoint-button"
            >
              Add Endpoint
            </Button>
          </ReadOnlyTooltip>
        </PageToolbar>
        <Table
          data-cy="endpoints-table"
          response={endpoints}
          requestElems={endpointsCtx}
          variant="hover"
          emptyState={
            <Box my={12}>
              <Heading size="sm" mb={2}>
                Set up an endpoint to get started
              </Heading>
              {!hideEventTypes && (
                <Text fontSize="sm">
                  For a list of events you can subscribe to, take a look at the{" "}
                  <StyledLink
                    as={Link}
                    color="interactive.accent"
                    to={routeResolver.getRoute("event-types")}
                    fontWeight="semibold"
                  >
                    Event Catalog
                  </StyledLink>
                  .
                </Text>
              )}
            </Box>
          }
        >
          <Thead>
            <Tr>
              <Th width="80%">URL</Th>
              <Th isNumeric minW="70px" whiteSpace="nowrap">
                Error Rate
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {streamEndpoints &&
              streamEndpoints.map((sink) => <StreamSinkRow key={sink.id} sink={sink} />)}

            {pollingEndpoints &&
              pollingEndpoints.map((s: any) => (
                <PollingEndpointRow
                  key={s.id}
                  sink={s}
                  to={routeResolver.getRoute("endpoints.polling._id", { sinkId: s.id })}
                />
              ))}

            {webhookEndpoints &&
              webhookEndpoints.map((we) => <EndpointRow key={we.id} endpoint={we} />)}

            {!endpoints && (
              <Tr>
                <TableCell>
                  <SkeletonText noOfLines={1} />
                </TableCell>
                <TableCell>
                  <SkeletonText noOfLines={1} />
                </TableCell>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>
    </>
  );
}

const PollingEndpointRow = (props: { sink: any; to: string }) => {
  const { sink, to } = props;
  const user = useAppSelector((state) => state.auth.user)!;
  const sinkIdOrUid = sink.uid || sink.id;

  return (
    <Tr opacity={sink.disabled ? "60%" : "100%"}>
      <TableCell to={to} scope="row" maxW={0} py={1}>
        <HStack spacing={4}>
          <RotateLeftIcon />
          <Stack spacing={0.5} minH="36px" justifyContent="center">
            <HStack>
              <Tag size="sm" colorScheme="blue">
                Polling Endpoint
              </Tag>
              <Text lineHeight="normal">{`${getServerUrl()}/api/v1/app/${
                user.app.id
              }/poller/${sinkIdOrUid}`}</Text>
            </HStack>
            {sink.disabled && (
              <Tag size="sm" colorScheme="red" ml={2}>
                Disabled
              </Tag>
            )}
          </Stack>
        </HStack>
      </TableCell>
      <TableCell to={to} isNumeric></TableCell>
    </Tr>
  );
};

export const isDynamicEndpointUrl = (url: string) => {
  return url === "https://callback.local";
};
