import { useState } from "react";
import { Box, Collapse, Grid, HStack, Input, Stack, Text } from "@chakra-ui/react";

import { IEndpointTypeItem } from "./index";

interface IEndpointTypeSelectorProps {
  isSelectorOpen: boolean;
  onSetSelected: (type: string) => void;
  allIntegrations: IEndpointTypeItem[];
}

export default function EndpointTypeSelector({
  isSelectorOpen,
  onSetSelected,
  allIntegrations,
}: IEndpointTypeSelectorProps) {
  const [search, setSearch] = useState("");

  return (
    <Stack>
      <Collapse in={isSelectorOpen} animateOpacity>
        <Box
          maxH="24em"
          maxW="50em"
          overflowY="auto"
          p={2}
          border="1px solid"
          borderColor="gray.200"
          _dark={{ borderColor: "gray.600" }}
          borderRadius="md"
          mb={4}
        >
          <Input
            mb={2}
            size="sm"
            variant="outline"
            placeholder="Search integrations..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <Grid templateColumns="repeat(3, 1fr)" gap={2}>
            {allIntegrations
              .filter(
                (item) =>
                  item.name.toLowerCase().includes(search.toLowerCase()) ||
                  item.description.toLowerCase().includes(search.toLowerCase())
              )
              .map((item) => (
                <EndpointTypeListItem
                  key={item.type}
                  {...item}
                  onClick={() => onSetSelected(item.type)}
                />
              ))}
          </Grid>
        </Box>
      </Collapse>
    </Stack>
  );
}

function EndpointTypeListItem(props: IEndpointTypeItem & { onClick: () => void }) {
  const { name, icon, description, onClick } = props;

  return (
    <Box
      p={4}
      border="1px solid"
      borderColor="gray.200"
      _dark={{ borderColor: "gray.600" }}
      borderRadius="md"
      minH="100px"
      transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
      bgColor="background.secondary"
      as="button"
      sx={{
        "&:hover": {
          bgColor: "background.hover",
        },
      }}
      onClick={onClick}
    >
      <Stack minH="32px" spacing={0}>
        <HStack>
          {icon}
          <Text textAlign="left" fontWeight="semibold">
            {name}
          </Text>
        </HStack>
        <Text textAlign="left" fontSize="sm" color="text.secondary" noOfLines={2}>
          {description && description.length > 0
            ? description
            : `Connect messages to ${name}`}
        </Text>
      </Stack>
    </Box>
  );
}
