import * as React from "react";
import { useState } from "react";
import {
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  Input,
  Stack,
  StackDivider,
  Text,
  Tooltip,
  IconButton,
} from "@chakra-ui/react";
import { Add, AddCircle, Close, Undo } from "@material-ui/icons";
import { useQuery, useQueryClient } from "react-query";
import { EndpointHeadersOut } from "svix";

import { getApiError } from "@svix/common/utils";
import Button from "@svix/common/widgets/Button";
import Card from "@svix/common/widgets/Card";

import { useAppSelector } from "src/hooks/store";
import { useLoadingManual } from "src/utils";

interface HeaderRow {
  header: string;
  value?: string;
  isSensitive: boolean;
  status: HeaderRowStatus;
}

enum HeaderRowStatus {
  Unchanged = "unchanged",
  New = "new",
  Modified = "modified",
  Removed = "removed",
}

function getSortedHeaders(headers: EndpointHeadersOut | undefined) {
  if (!headers) {
    return [];
  }

  const sorted: HeaderRow[] = Object.entries(headers.headers).map(([k, v]) => ({
    header: k,
    value: v,
    isSensitive: false,
    status: HeaderRowStatus.Unchanged,
  }));

  for (const sensitive of headers.sensitive) {
    sorted.push({
      header: sensitive,
      isSensitive: true,
      status: HeaderRowStatus.Unchanged,
    });
  }

  return sorted.sort((a, b) => a.header.localeCompare(b.header));
}

function getModifiedHeaderRows(headers: HeaderRow[], headersPatch: IHeadersPatch) {
  const addedHeaders = Object.keys(headersPatch).filter(
    (k) => headersPatch[k] !== null && !headers.find((h) => h.header === k)
  );

  return headers
    .map((s) => {
      if (headersPatch[s.header] === null) {
        return {
          ...s,
          status: HeaderRowStatus.Removed,
        };
      } else if (headersPatch[s.header]) {
        return {
          ...s,
          value: headersPatch[s.header],
          status: HeaderRowStatus.Modified,
        };
      }
      return s;
    })
    .concat(
      addedHeaders.map((k) => ({
        header: k,
        value: headersPatch[k]!,
        isSensitive: false,
        status: HeaderRowStatus.New,
      }))
    );
}

export interface IHeadersPatch {
  [key: string]: string | null;
}

interface ICustomHeadersProps {
  queryKey: string[];
  getHeaders: () => Promise<EndpointHeadersOut>;
  onSave: (headers: IHeadersPatch) => Promise<void>;
}

type LegacyRef = React.MutableRefObject<HTMLInputElement | null>;

export default function CustomHeaders(props: ICustomHeadersProps) {
  const { queryKey, getHeaders, onSave } = props;
  const isReadOnly = useAppSelector((state) => state.embedConfig.isReadOnly);
  const [error, setError] = useState<Error | undefined>();
  const [headersPatch, setHeadersPatch] = useState<IHeadersPatch>({});

  const keyField = React.useRef() as LegacyRef;
  const valueField = React.useRef() as LegacyRef;
  const queryClient = useQueryClient();

  const { data: customHeaders } = useQuery(queryKey, () => {
    return getHeaders();
  });
  const savedHeaders = getSortedHeaders(customHeaders);

  const resetHeaderChanges = () => {
    setHeadersPatch({});
    setError(undefined);
  };

  const addHeader = () => {
    setError(undefined);
    const k = keyField.current?.value.trim();
    const v = valueField.current?.value.trim();

    if (k && v) {
      keyField.current!.value = "";
      valueField.current!.value = "";
      keyField.current!.focus();
      setHeadersPatch((prev) => ({ ...prev, [k]: v }));
    }
  };

  const removeHeader = (header: string) => {
    setError(undefined);
    const newPatch = { ...headersPatch };
    delete newPatch[header];
    if (savedHeaders.find((s) => s.header === header)) {
      newPatch[header] = null;
    }
    setHeadersPatch(newPatch);
  };

  const undoHeaderChange = (header: string) => {
    setHeadersPatch((prev) => {
      const newPatch = { ...prev };
      delete newPatch[header];
      return newPatch;
    });
  };

  const headers = getModifiedHeaderRows(savedHeaders, headersPatch);

  const [isSaving, _, maybeSaveHeaders] = useLoadingManual(async () => {
    setError(undefined);
    try {
      await onSave(headersPatch);
      resetHeaderChanges();
    } catch (err) {
      setError(err as Error);
    } finally {
      queryClient.invalidateQueries(queryKey);
    }
  }, [headersPatch]);

  const numChanges = Object.keys(headersPatch).length;

  return (
    <Card maxH={540} overflowY="scroll" title="Custom Headers">
      <Stack divider={<StackDivider borderColor="background.modifier.border" />}>
        {headers.map((row, i) => (
          <HStack key={i}>
            <HStack w="100%">
              {row.status === HeaderRowStatus.New && (
                <AddCircle style={{ fontSize: 16, opacity: 0.4 }} />
              )}
              <Text
                fontWeight={
                  row.status === HeaderRowStatus.Modified ||
                  row.status === HeaderRowStatus.New
                    ? "bold"
                    : "normal"
                }
                textDecoration={
                  row.status === HeaderRowStatus.Removed ? "line-through" : "none"
                }
              >
                {row.header}
              </Text>
            </HStack>
            {row.isSensitive ? (
              <Flex w="100%">
                &bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;
              </Flex>
            ) : (
              <Text
                w="100%"
                fontWeight={
                  row.status === HeaderRowStatus.Modified ||
                  row.status === HeaderRowStatus.New
                    ? "bold"
                    : "normal"
                }
                textDecoration={
                  row.status === HeaderRowStatus.Removed ? "line-through" : "none"
                }
              >
                {row.value}
              </Text>
            )}
            {!isReadOnly && (
              <>
                {row.status === HeaderRowStatus.Removed ||
                row.status === HeaderRowStatus.Modified ? (
                  <Tooltip hasArrow aria-label="Undo" label="Undo" placement="top">
                    <IconButton
                      isRound
                      size="sm"
                      variant="ghost"
                      colorScheme="gray"
                      icon={<Undo style={{ fontSize: 16 }} />}
                      aria-label="Undo"
                      onClick={() => undoHeaderChange(row.header)}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip
                    hasArrow
                    aria-label="Remove Header"
                    label="Remove Header"
                    placement="top"
                  >
                    <IconButton
                      isRound
                      size="sm"
                      variant="ghost"
                      colorScheme="gray"
                      icon={<Close style={{ fontSize: 16 }} />}
                      aria-label="Remove Header"
                      onClick={() => removeHeader(row.header)}
                    />
                  </Tooltip>
                )}
              </>
            )}
          </HStack>
        ))}
        <FormControl isInvalid={!!error} id="customHeaders">
          {!isReadOnly && (
            <HStack pt={1}>
              <Input
                isInvalid={false}
                ref={keyField}
                id="key"
                variant="filled"
                placeholder="Key"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    addHeader();
                  }
                }}
              />
              <Input
                ref={valueField}
                id="value"
                isInvalid={false}
                variant="filled"
                placeholder="Value"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    addHeader();
                  }
                }}
              />
              <Tooltip
                hasArrow
                aria-label="Add Header"
                label="Add Header"
                placement="top"
              >
                <IconButton
                  size="sm"
                  variant="solid"
                  colorScheme="brand"
                  icon={<Add style={{ fontSize: 16 }} />}
                  aria-label="Add item"
                  onClick={addHeader}
                />
              </Tooltip>
            </HStack>
          )}
          <FormErrorMessage alignSelf="flex-end">{getApiError(error)}</FormErrorMessage>
          {numChanges > 0 && (
            <HStack justifyContent="flex-end" pt={4} spacing={2}>
              <Button onClick={resetHeaderChanges} colorScheme="gray">
                Cancel
              </Button>
              <Button onClick={maybeSaveHeaders} isLoading={isSaving}>
                {`Save (${numChanges} ${numChanges === 1 ? "change" : "changes"})`}
              </Button>
            </HStack>
          )}
        </FormControl>
      </Stack>
    </Card>
  );
}
