/* eslint-disable no-restricted-imports */
import {
  ModalContent as ChakraModalContent,
  ModalOverlay as ChakraModalOverlay,
  Modal as ChakraModal,
  ModalBody as ChakraModalBody,
  ModalHeader as ChakraModalHeader,
  ModalFooter as ChakraModalFooter,
  ModalCloseButton as ChakraModalCloseButton,
  ModalContentProps,
  ModalOverlayProps,
} from "@chakra-ui/react";

import { inIframe } from "../utils";

export const Modal = ChakraModal;
export const ModalBody = ChakraModalBody;
export const ModalHeader = ChakraModalHeader;
export const ModalFooter = ChakraModalFooter;
export const ModalCloseButton = ChakraModalCloseButton;

export function ModalOverlay(props: ModalOverlayProps) {
  const iframeOverlay = {
    backdropFilter: "blur(6px)",
    background: "none",
  };

  return <ChakraModalOverlay {...(inIframe() ? iframeOverlay : {})} {...props} />;
}

export function ModalContent(props: ModalContentProps) {
  return <ChakraModalContent {...props} boxShadow={inIframe() ? "dark-lg" : undefined} />;
}
