import { Box, Flex, Heading, SkeletonText, Thead, Tr, Th, Tbody } from "@chakra-ui/react";
import { Add } from "@material-ui/icons";
import { Link } from "react-router-dom";

import Button from "@svix/common/widgets/Button";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";
import {
  PageToolbar,
  BreadcrumbItem,
  Breadcrumbs,
} from "@svix/common/widgets/PageToolbar";
import Table from "@svix/common/widgets/Table";
import TableCell from "@svix/common/widgets/TableCell";

import { StreamSinkStatus } from "src/api/streamSinks";
import { routeResolver } from "src/App";
import { useAppPagination } from "src/hooks/api";
import { useAppSelector } from "src/hooks/store";
import StreamSinkRow from "src/screens/Stream/Sinks/StreamSinkRow";
import ReadOnlyTooltip from "src/widgets/ReadOnlyTooltip";

// FIXME STREAM_APP_PORTAL: Remove
const sampleResponse = {
  data: [
    {
      id: "sink_2yVWpws5CxuvlL5kPZVvI",
      uid: undefined,
      type: "http",
      config: {
        url: "http://localhost:4001/app_2mWIKid6dSYA0ICSArXlIP5O27T/stream/sinks/new",
        headers: {},
      },
      format: "json",
      status: StreamSinkStatus.ENABLED,
      currentIterator: undefined,
      disabledReason: undefined,
      createdAt: new Date("2024-12-12T21:34:19.483Z"),
      updatedAt: new Date("2024-12-12T21:34:20.590210Z"),
      batchSize: 100,
      maxWaitSecs: 10,
      eventTypes: [],
    },
    {
      id: "sink_2yVxRGfers6LVJtixrnmD",
      uid: undefined,
      type: "amazonS3",
      config: {
        bucket: "asdfsdfsa",
        accessKeyId: "asdfasdfasd",
        region: "asdfasdf",
      },
      format: "json",
      status: StreamSinkStatus.ENABLED,
      currentIterator: undefined,
      disabledReason: undefined,
      createdAt: new Date("2024-12-13T15:38:18.225Z"),
      updatedAt: new Date("2025-01-13T17:55:39.318297Z"),
      batchSize: 100,
      maxWaitSecs: 10,
      eventTypes: [],
    },
  ],
  iterator: "sink_2yVxRGfers6LVJtixrnmD",
  prevIterator: "-sink_2yVWpws5CxuvlL5kPZVvI",
  done: true,
};

export default function StreamSinksScreen() {
  const user = useAppSelector((state) => state.auth.user)!;

  const [sinks, sinksCtx] = useAppPagination("sinks", async (_iterator) => {
    // FIXME STREAM_APP_PORTAL: Add real API call
    return sampleResponse;
  });

  const isReadOnly = useAppSelector((state) => state.embedConfig.isReadOnly);

  return (
    <>
      <MetaTitle path={["Sinks", user.app.name]} />
      <Box id="sinks-list">
        <PageToolbar>
          <Breadcrumbs>
            <BreadcrumbItem>Sinks</BreadcrumbItem>
          </Breadcrumbs>
          <Flex flexGrow={1} />
          <ReadOnlyTooltip readOnly={isReadOnly}>
            <Button
              as={Link}
              size="sm"
              isDisabled={isReadOnly}
              to={isReadOnly ? "" : routeResolver.getRoute("stream.sinks.new")}
              variant="outlineSecondary"
              leftIcon={<Add />}
              id="add-sink-button"
            >
              New Sink
            </Button>
          </ReadOnlyTooltip>
        </PageToolbar>
        <Table
          data-cy="sinks-table"
          response={sinks}
          requestElems={sinksCtx}
          variant="hover"
          emptyState={
            <Box my={12}>
              <Heading size="sm" mb={2}>
                Set up a sink to get started
              </Heading>
            </Box>
          }
        >
          <Thead>
            <Tr>
              <Th>Sink</Th>
              <Th isNumeric>Status</Th>
            </Tr>
          </Thead>
          <Tbody>
            {sinks?.data.map((sink: any) => (
              <StreamSinkRow key={sink.id} sink={sink} />
            ))}
            {!sinks && (
              <Tr>
                <TableCell>
                  <SkeletonText noOfLines={1} />
                </TableCell>
                <TableCell>
                  <SkeletonText noOfLines={1} />
                </TableCell>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>
    </>
  );
}
