import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Checkbox,
  Divider,
  Flex,
  FormLabel,
  Stack,
  Heading,
  HStack,
  Skeleton,
  Text,
  Tooltip,
  useBoolean,
  Image,
  Collapse,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  ArrowForwardIos,
  Info,
  ExpandMore,
  ExpandLess,
  Refresh,
} from "@material-ui/icons";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router";
import { Link, Redirect } from "react-router-dom";
import { EndpointIn } from "svix";
import { TransformationTemplateApi } from "svix/dist/openapi";
import * as yup from "yup";

import { setErrors } from "@svix/common/formUtils";
import { generateSvixPlayRequestUrl } from "@svix/common/play";
import { capitalize } from "@svix/common/utils";
import Button from "@svix/common/widgets/Button";
import EventsList from "@svix/common/widgets/EventsList";
import Form, { GeneralFormErrors } from "@svix/common/widgets/Form";
import TextArea from "@svix/common/widgets/form/TextArea";
import TextField from "@svix/common/widgets/form/TextField";
import StyledLink from "@svix/common/widgets/Link";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";
import {
  PageToolbar,
  BreadcrumbItem,
  Breadcrumbs,
} from "@svix/common/widgets/PageToolbar";
import SubmitButton from "@svix/common/widgets/SubmitButton";

import { getSvix } from "src/api";
import { SinksApi } from "src/api/sinks";
import { StreamSinkStatus } from "src/api/streamSinks";
import { routeResolver } from "src/App";
import { useAllEventTypes, useAppQuery } from "src/hooks/api";
import { useOrgSettings } from "src/hooks/common";
import { useAppSelector } from "src/hooks/store";
import webhookIcon from "src/img/webhook.svg";
import { isEE } from "src/utils";
import EndpointTypeSelector from "./EndpointTypeSelector";
import PollingEndpointForm from "./PollingEndpoint";
import TransformationTemplateForm from "./TemplateForm";
import { MAX_CHANNELS } from "../Endpoint/properties/Channels";
import ChannelsAutocomplete from "../Endpoint/properties/ChannelsAutosuggest";
import { SinkTypesMetadata } from "../Stream/constants";
import StreamSinkCreateForm from "../Stream/SinkCreate/SinkCreateForm";

const schema = yup.object().shape({
  url: yup.string().url("Invalid URL"),
  description: yup.string(),
  filterTypes: yup.mixed(),
  customHeaders: yup.object().nullable(),
  channels: yup.array().of(yup.string()).max(MAX_CHANNELS).nullable(),
  rateLimit: yup
    .number()
    .nullable()
    .transform((_, val) => (val !== "" && val !== null ? Number(val) : null)),
});

const DEFAULT_VALUES = {
  url: "",
  version: 1,
  filterTypes: [] as string[],
  customHeaders: undefined,
  rateLimit: undefined,
};

const HTTP_INTEGRATION_ID = "webhook";
const POLLER_INTEGRATION_ID = "poller";

export interface IEndpointTypeItem {
  type: string;
  icon: React.ReactNode;
  name: string;
  description: string;
}

const supportedSinkTypes: string[] = [
  "http",
  "amazonS3",
  "azureBlobStorage",
  "googleCloudStorage",
];

const GenericWebhookEndpointType: IEndpointTypeItem = {
  type: HTTP_INTEGRATION_ID,
  icon: <Image src={webhookIcon} alt="Webhook" width="24px" height="24px" />,
  name: "Webhook",
  description: "Create a webhook endpoint to receive events",
};

const PollerEndpointType: IEndpointTypeItem = {
  type: POLLER_INTEGRATION_ID,
  icon: <Refresh />,
  name: "Polling Endpoint",
  description: "Receive events by polling an endpoint",
};

export default function EndpointCreateScreen() {
  const router = useHistory();
  const { integrationId = HTTP_INTEGRATION_ID } = useParams<{ integrationId?: string }>();
  const { data: templateList, isLoading } = useAppQuery(
    ["transformationTemplates"],
    async () => {
      const svix = getSvix();
      const api = new TransformationTemplateApi(svix._configuration);
      return api.v1TransformationTemplateList({ limit: 250 });
    },
    {
      enabled: !isEE,
    }
  );

  const [isSelectorOpen, setIsSelectorOpen] = useBoolean();
  const { data: orgSettings } = useOrgSettings();

  const selectedTemplate = templateList?.data.find((t) => t.id === integrationId);

  let selected: IEndpointTypeItem;
  let EndpointForm = <EndpointCreateForm />;

  if (isLoading || integrationId === HTTP_INTEGRATION_ID) {
    selected = GenericWebhookEndpointType;
    EndpointForm = <EndpointCreateForm />;
  } else if (integrationId === POLLER_INTEGRATION_ID) {
    selected = PollerEndpointType;
    EndpointForm = <PollingEndpointForm />;
  } else if (selectedTemplate) {
    selected = {
      type: selectedTemplate.id,
      icon: (
        <Image
          src={selectedTemplate.logo}
          alt={selectedTemplate.name}
          width="24px"
          height="24px"
        />
      ),
      name: selectedTemplate.name,
      description: selectedTemplate.description,
    };
    EndpointForm = <TransformationTemplateForm template={selectedTemplate} />;
  } else if (integrationId in SinkTypesMetadata) {
    selected = {
      ...SinkTypesMetadata[integrationId],
      type: integrationId,
    };
    if (integrationId === "http") {
      EndpointForm = <EndpointCreateForm isFIFOEndpoint />;
    } else {
      EndpointForm = <StreamSinkCreateForm type={integrationId} />;
    }
  } else {
    // No integration found, redirect to HTTP endpoint
    return <Redirect to={routeResolver.getRoute("endpoints")} />;
  }

  const advancedEndpointTypesEnabled = !!orgSettings?.enableMessageStream;

  const onSetSelected = (integrationId: string) => {
    setIsSelectorOpen.toggle();
    router.push(
      routeResolver.getRoute("endpoints.new._id", {
        integrationId,
      })
    );
  };

  const allIntegrations: IEndpointTypeItem[] = [GenericWebhookEndpointType];
  if (advancedEndpointTypesEnabled) {
    allIntegrations.push(PollerEndpointType);
    allIntegrations.push(
      ...supportedSinkTypes.map((type) => ({ type, ...SinkTypesMetadata[type] }))
    );
  }

  if (templateList?.data) {
    allIntegrations.push(
      ...templateList.data.map((t) => ({
        type: t.id,
        icon: <Image src={t.logo} alt={t.name} width="24px" height="24px" />,
        name: t.name,
        description: t.description,
      }))
    );
  }

  const showOptions = allIntegrations.length > 1;

  return (
    <>
      <MetaTitle path={["New Endpoint"]} />

      <PageToolbar>
        <HStack maxW="50em" justifyContent="space-between" w="100%">
          <Breadcrumbs>
            <BreadcrumbItem to={routeResolver.getRoute("home")}>Endpoints</BreadcrumbItem>
            <BreadcrumbItem>New Endpoint</BreadcrumbItem>
          </Breadcrumbs>
          {showOptions && (
            <Skeleton isLoaded={!isLoading}>
              <Stack>
                <Button
                  colorScheme="gray"
                  variant="solid"
                  onClick={setIsSelectorOpen.toggle}
                >
                  <Box mr={3}>
                    <HStack spacing={3}>
                      {selected.icon}
                      <Stack justifyContent="center" minH="32px" spacing={0}>
                        <Text textAlign="left" fontWeight="semibold">
                          {selected.name}
                        </Text>
                      </Stack>
                      {isSelectorOpen ? <ExpandLess /> : <ExpandMore />}
                    </HStack>
                  </Box>
                </Button>
              </Stack>
            </Skeleton>
          )}
        </HStack>
      </PageToolbar>

      <Stack>
        <EndpointTypeSelector
          isSelectorOpen={isSelectorOpen}
          onSetSelected={onSetSelected}
          allIntegrations={allIntegrations}
        />
        {EndpointForm}
      </Stack>
    </>
  );
}

function EndpointCreateForm({ isFIFOEndpoint = false }: { isFIFOEndpoint?: boolean }) {
  const defaultValues = DEFAULT_VALUES as EndpointIn;
  const user = useAppSelector((state) => state.auth.user)!;
  const { stringsOverrides, hideEventTypes, hideUseSvixPlay } = useAppSelector(
    (state) => state.embedConfig
  );
  const [hasEndpointRateLimit, setHasEndpointRateLimit] = useBoolean();
  const history = useHistory();
  const formCtx = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { data: orgSettings } = useOrgSettings();

  const { data: availableEvents } = useAllEventTypes();

  function useSvixPlay(e: any) {
    e.preventDefault();
    formCtx.setValue("url", generateSvixPlayRequestUrl(), {
      shouldDirty: true,
    });
  }

  async function onAddEndpoint(form: EndpointIn) {
    const dh = getSvix();
    if (form.filterTypes && form.filterTypes.length === 0) {
      delete form["filterTypes"];
    }
    if (form.channels && form.channels.length === 0) {
      delete form["channels"];
    }

    if (!hasEndpointRateLimit) {
      delete form["rateLimit"];
    }

    if (isFIFOEndpoint) {
      try {
        const sv = getSvix();
        const api = new SinksApi(sv);
        const sink = await api.createStreamSink(user.app.id, {
          type: "http",
          status: StreamSinkStatus.ENABLED,
          config: {
            url: form.url,
          },
          batchSize: 100,
          maxWaitSecs: 10,
          eventTypes: form.filterTypes ?? undefined,
          format: "json",
        });
        history.push(routeResolver.getRoute("endpoints.stream._id", { sinkId: sink.id }));
      } catch (e) {
        setErrors(formCtx.setError, e.body);
      }
    } else {
      try {
        const endpoint = await dh.endpoint.create(user.app.id, form);
        history.push(routeResolver.getRoute("endpoints._id", { endpId: endpoint.id }));
      } catch (e) {
        setErrors(formCtx.setError, e.body);
      }
    }
  }

  const eePlayHelper = (
    <span>
      Configure an endpoint or{" "}
      <Flex alignItems="center" display="inline-flex">
        <StyledLink
          type="button"
          as="button"
          textDecoration="underline"
          fontWeight="medium"
          onClick={useSvixPlay}
          mr={1}
        >
          <span>add a test URL</span>
        </StyledLink>
        <Tooltip
          label="This will generate a unique endpoint to receive and inspect incoming webhooks."
          hasArrow
        >
          <Info fontSize="small" style={{ opacity: 0.6 }} />
        </Tooltip>
      </Flex>
    </span>
  );

  const cloudPlayHelper = (
    <span>
      Configure an endpoint or test{" "}
      <Flex alignItems="center" display="inline-flex">
        <StyledLink
          type="button"
          as="button"
          textDecoration="underline"
          fontWeight="medium"
          onClick={useSvixPlay}
          mr={1}
        >
          <span>with Svix Play</span>
        </StyledLink>
        <Tooltip
          label="Svix Play instantly gives you a unique endpoint to receive and inspect incoming webhooks."
          hasArrow
        >
          <Info fontSize="small" style={{ opacity: 0.6 }} />
        </Tooltip>
      </Flex>
    </span>
  );

  return (
    <Form onSubmit={onAddEndpoint} {...formCtx}>
      <Box maxW="50em">
        <Stack spacing={5}>
          <TextField
            autoFocus
            control={formCtx.control}
            name="url"
            label="Endpoint URL"
            type="url"
            isRequired
            placeholder="e.g. https://www.example.com/webhook"
            helperText={!hideUseSvixPlay && (isEE ? eePlayHelper : cloudPlayHelper)}
          />
          {!isFIFOEndpoint && (
            <TextArea
              name="description"
              control={formCtx.control}
              label="Description"
              placeholder="An optional description of what this endpoint is used for."
            />
          )}
          <Stack spacing={5}>
            {!hideEventTypes && (
              <EventsList
                availableEvents={availableEvents?.data || []}
                control={formCtx.control}
                name="filterTypes"
                label={
                  <Flex alignItems="center" justifyContent="space-between">
                    <span>Subscribe to events</span>
                    <StyledLink
                      fontSize="sm"
                      display="flex"
                      alignItems="center"
                      color="interactive.accent"
                      to={routeResolver.getRoute("event-types")}
                    >
                      Event Catalog
                      <ArrowForwardIos style={{ fontSize: 15, marginLeft: 4 }} />
                    </StyledLink>
                  </Flex>
                }
                emptyState="Receiving all events."
              />
            )}
            {orgSettings?.enableChannels && !isFIFOEndpoint && (
              <ChannelsAutocomplete
                label={capitalize(stringsOverrides.channelsMany)}
                name="channels"
                control={formCtx.control}
                helperText={stringsOverrides.channelsHelp}
              />
            )}
          </Stack>
          {!isFIFOEndpoint && (
            <Accordion allowToggle borderTopWidth={0} my={4} w="100%">
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Heading size="sm" as="div" flex="1" textAlign="left">
                      Advanced Configuration
                    </Heading>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Stack spacing={5}>
                    <Box my={2}>
                      <Checkbox
                        name="enableEndpointRatelimit"
                        isChecked={hasEndpointRateLimit}
                        onChange={setHasEndpointRateLimit.toggle}
                      >
                        Enable endpoint rate limiting (throttling)?
                      </Checkbox>
                      <Collapse in={hasEndpointRateLimit} animateOpacity>
                        <Box mt={3}>
                          <TextField
                            control={formCtx.control}
                            label="Rate Limit (per second)"
                            helperText="Message delivery will be throttled to this rate."
                            name="rateLimit"
                            placeholder="0"
                            type="number"
                            maxW="16em"
                          />
                        </Box>
                      </Collapse>
                    </Box>
                    <Box>
                      <FormLabel>Custom Headers</FormLabel>
                      <Text size="sm">
                        If you want to configure custom headers for this endpoint, you can
                        do so from the endpoint details page after it's created.
                      </Text>
                    </Box>
                  </Stack>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          )}
          <input name="version" type="hidden" value={DEFAULT_VALUES.version} />
        </Stack>
        <GeneralFormErrors />
        <Divider mt={4} />

        <HStack mt={4} spacing={4}>
          <Button colorScheme="gray" as={Link} to={routeResolver.getRoute("endpoints")}>
            Cancel
          </Button>

          <SubmitButton isLoading={formCtx.formState.isSubmitting}>Create</SubmitButton>
        </HStack>
      </Box>
    </Form>
  );
}
