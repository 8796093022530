import { Fragment } from "react";
import { Tr, Stack, Tag, Text, Box, HStack } from "@chakra-ui/react";

import TableCell from "@svix/common/widgets/TableCell";

import { StreamSinkOut, StreamSinkStatus } from "src/api/streamSinks";
import { routeResolver } from "src/App";
import { getTypedNestedValue } from "src/utils";
import { SinkTypesMetadata, StreamSinkStatusNames } from "../constants";

export default function StreamSinkRow(props: { sink: StreamSinkOut }) {
  const { sink } = props;
  const endpointType = SinkTypesMetadata[sink.type];

  return (
    <Tr>
      <TableCell
        to={routeResolver.getRoute("endpoints.stream._id", { sinkId: props.sink.id })}
      >
        <HStack spacing={4}>
          <Box
            w="20px"
            h="20px"
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            {endpointType.icon}
          </Box>
          <Stack>
            <Tag w="fit-content" size="sm">
              {endpointType.name}
            </Tag>
            {sink.type === "http" ? (
              <Text fontSize="sm">{sink.config.url}</Text>
            ) : (
              <Text fontSize="sm">
                {endpointType.values
                  .filter((value) => value.highlight)
                  .map((value, index, arr) => (
                    <Fragment key={value.key}>
                      <Box display="inline-block">
                        <b>{value.label}: </b>
                        <Text fontSize="sm" display="inline-block">
                          {getTypedNestedValue(sink.config, value.key)}
                        </Text>
                      </Box>
                      {index < arr.length - 1 && (
                        <Box display="inline-block" mx={2}>
                          •
                        </Box>
                      )}
                    </Fragment>
                  ))}
              </Text>
            )}
          </Stack>
        </HStack>
      </TableCell>
      <TableCell isNumeric>
        <Tag colorScheme={StreamSinkStatusNames[sink.status as StreamSinkStatus].color}>
          <Text>{StreamSinkStatusNames[sink.status as StreamSinkStatus].name}</Text>
        </Tag>
      </TableCell>
    </Tr>
  );
}
