import { lazy, Suspense, useState } from "react";
import { Box, Stack, HStack, Skeleton, Text, Spacer, Flex } from "@chakra-ui/react";
import { ArrowForwardIos } from "@material-ui/icons";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";

import { setErrors } from "@svix/common/formUtils";
import Button from "@svix/common/widgets/Button";
import EventsList from "@svix/common/widgets/EventsList";
import Form, { GeneralFormErrors } from "@svix/common/widgets/Form";
import { Lang } from "@svix/common/widgets/form/CodeEditor/Lang";
import TextField from "@svix/common/widgets/form/TextField";
import StyledLink from "@svix/common/widgets/Link";
import SubmitButton from "@svix/common/widgets/SubmitButton";

import { getSvix } from "src/api";
import { SinksApi } from "src/api/sinks";
import { StreamSinkIn, StreamSinkStatus } from "src/api/streamSinks";
import { routeResolver } from "src/App";
import { useAllEventTypes } from "src/hooks/api";
import { useAppSelector } from "src/hooks/store";
import { SinkTypesMetadata } from "../constants";

const CodeEditor = lazy(() => import("@svix/common/widgets/form/CodeEditor"));

interface StreamSinkCreateFormProps {
  type: string;
}

export default function StreamSinkCreateForm({ type }: StreamSinkCreateFormProps) {
  const history = useHistory();
  const user = useAppSelector((state) => state.auth.user)!;
  const DEFAULT_VALUES = {
    batchSize: 100,
    maxWaitSecs: 10,
    transformation: STREAM_DEFAULT_CODE,
  };
  const formCtx = useForm<StreamSinkIn>({
    defaultValues: DEFAULT_VALUES,
  });

  const onSubmit = async (form: StreamSinkIn) => {
    try {
      const sinkIn: StreamSinkIn = {
        ...form,
      };
      if (sinkIn.eventTypes?.length === 0) {
        sinkIn.eventTypes = undefined;
      }

      const sv = getSvix();
      const api = new SinksApi(sv);
      const sink = await api.createStreamSink(user.app.id, {
        type: type,
        status: StreamSinkStatus.ENABLED,
        config: {
          ...form.config,
        },
        batchSize: form.batchSize,
        maxWaitSecs: form.maxWaitSecs,
        eventTypes: form.eventTypes,
        format: "json",
      });
      history.push(routeResolver.getRoute("endpoints.stream._id", { sinkId: sink.id }));
    } catch (error) {
      setErrors(formCtx.setError, error);
    }
  };

  const [code, setCode] = useState(STREAM_DEFAULT_CODE);
  const { data: eventTypesData } = useAllEventTypes();

  return (
    <Form {...formCtx} onSubmit={onSubmit}>
      <Stack w="100%" maxW="50em" spacing={6}>
        <Box>
          <Text mb={2} fontSize="md">
            Configure connection
          </Text>
          <Box border="1px solid" borderColor="gray.200" borderRadius="md" p={3}>
            <SinkConfigFormInner formCtx={formCtx} type={type} />
          </Box>
        </Box>

        <EventsList
          availableEvents={eventTypesData?.data || []}
          control={formCtx.control}
          name="eventTypes"
          label={
            <Stack>
              <Flex alignItems="center" justifyContent="space-between">
                <span>Subscribe to events</span>
                <StyledLink
                  fontSize="sm"
                  display="flex"
                  alignItems="center"
                  color="interactive.accent"
                  to={routeResolver.getRoute("event-types")}
                >
                  Event Catalog
                  <ArrowForwardIos style={{ fontSize: 15, marginLeft: 4 }} />
                </StyledLink>
              </Flex>
              <Text fontSize="sm" color="text.secondary" fontWeight="normal">
                These are the events supported by this connector. Select the ones you want
                to receive.
              </Text>
            </Stack>
          }
          emptyState="Receiving all events."
        />
        <Stack spacing={1}>
          <Text fontWeight="semibold">Transformation code</Text>
          <Text fontSize="sm" color="text.secondary">
            This is the code that will be executed when an event is received. You can
            modify it to customize your integration.
          </Text>
          <Suspense fallback={<Skeleton h="12em" />}>
            <CodeEditor lang={Lang.Javascript} value={code} onChange={setCode} dark />
          </Suspense>
        </Stack>

        <GeneralFormErrors />
        <HStack mt={4}>
          <Spacer />
          <Button
            variant="outline"
            colorScheme="gray"
            as={Link}
            to={routeResolver.getRoute("endpoints")}
          >
            Cancel
          </Button>
          <SubmitButton>Create</SubmitButton>
        </HStack>
      </Stack>
    </Form>
  );
}

// FIXME STREAM_APP_PORTAL: Remove any
function SinkConfigFormInner({ formCtx, type }: { formCtx: any; type: string }) {
  const sinkValues = SinkTypesMetadata[type];
  return (
    <Stack spacing={5}>
      {sinkValues.values.map(({ key, label, placeholder, description, sensitive }) => (
        <TextField
          key={key}
          control={formCtx.control}
          name={`config.${key}`}
          label={label}
          placeholder={placeholder}
          required
          helperText={description}
          type={sensitive ? "password" : "text"}
        />
      ))}
    </Stack>
  );
}

// FIXME STREAM_APP_PORTAL: Add custom transformation for each sink type
export const STREAM_DEFAULT_CODE = `/**
 * @param input - The input object
 * @param input.events - The array of messages in the batch
 * @param input.events[].payload - The message payload
 * @param input.events[].eventType - The message event type
 * 
 * @returns Object containing the request body
 * @returns returns.requestBody - The body that will be sent to the endpoint
 */
function handler(input) {
  const events = input.events.map((evt) => ({
    payload: evt.payload,
    eventType: evt.eventType
  }));

  return {
    requestBody: JSON.stringify({ data: events })
  }
}
`;
